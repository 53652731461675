import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import './index.scss'
import Catering from './Catering'
import OurStory from './OurStory'
import Form from './Form'
import AutoMenu from '../../react/AutoMenu'


export const customOrderAppPaths = ["/menu", "/order"]


class TwoGuysFromItalyGlendale extends Component {
  render() {
    return (
      <div className="twoguysfromitalyglendale">
      </div>
    );
  }
}

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu", component: () => <Redirect to="/order"></Redirect>},
  {path: "/catering", label: "Catering", component: Catering},
  {path: "/about", label: "Our Story", component: OurStory},
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label}) => (
          <li><NavLink className="page-link" to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-page">
        <div className="order-benefits-wrapper">
          <div className="order-benefits">
            {/* <Form></Form> */}
            <div
              className="text"
               >

              <p>Domenicos, Pasadena CA</p>
              <p>Pickup & Delivery<br/>Pickup Best Option</p>
            </div>
          </div>

        </div>
        {/* <AutoMenu shouldFocus></AutoMenu> */}
      </div>
    );
  }
}
// logo link: https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny
export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">

        <div className="sections-wrapper">
          {/* <section className="section banner">
            <Form></Form>
          </section> */}

          <section className="section banner">
            <div className="content"
              style={{
                // backgroundImage: `url(https://afag.imgix.net/domenicos-italian-restaurant/la-best-award.jpg?w=900)`,
                backgroundImage: `url(https://afag.imgix.net/domenicos-italian-restaurant/exterior.jpg?auto=format)`,
                backgroundPosition: "center 20%"
              }}
              >
                <div className="text">
                  <h1><div className="bg">Experience Mama Rosa's Original Recipes</div></h1>
                  <div className="button-wrapper">
                    <Link
                      className="order-online-button"
                      to="/order">Order Pickup / Delivery
                    </Link>
                  </div>
                  <p>Pasadena Location</p>
                </div>

              </div>
          </section>

          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://afag.imgix.net/domenicos-italian-restaurant/wall-menu.jpg?w=600&blur=15)`,

              }}
              >
              <div className="text">
                <h2>Menu</h2>
                <Link to="/menu">view menu</Link>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://images.unsplash.com/photo-1524932326868-56e1f1ede465?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80)`,

              }}
              >
              <div className="text">
                <h2>Catering</h2>
                <Link to="/catering">view catering</Link>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://afag.imgix.net/domenicos-italian-restaurant/la-best-award.jpg?w=900)`,
              }}
              >

              <div className="text">
                <h2>Visit Us</h2>
                <a href="https://www.google.com/maps/place/Andy's+Restaurant/@34.168113,-118.3723128,17z/data=!3m1!4b1!4m5!3m4!1s0x80c295f2b7d56861:0x2d705113fb4c2f76!8m2!3d34.168113!4d-118.3701241">
                2411 E Washington Blvd, Pasadena</a>
                {/* <p>Glendale, CA 91206</p> */}
                {/* <small><a href="tel:+18182400020">(818)240-0020</a></small> */}
              </div>
            </div>
          </section>
        </div>



        {/* <div className="order-benefits home">

          <div
            className="text"
             >
           <h1><img style={{width: "125px"}}
             src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny" alt="Two Guys from Italy — Glendale"/></h1>
            <small>405 N Verdugo Rd Glendale, CA</small><br/>
            <small><a href="tel:+18182400020">(818)240-0020</a></small>

          </div>
        </div> */}
      </div>
    );
  }
}

const foodImages = [
  'https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/pizza.jpg?w=800&auto=compress',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_d.jpg',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_e.jpg'
]

export default TwoGuysFromItalyGlendale;
